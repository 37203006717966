export default {
    kk: {
        ru: "Русский",
        "en": "Английский",
        "kk": "Казахский",
        "show_all": "Показать все",
        "select_filter": "Выбрать фильтр",
        "filters": "Фильтры",
        find: "Поиск",
        "reset": "Сбросить",
        close: "Закрыть",
        "yes": "Да",
        "no": "Нет",
        "no_data": "Данных нет",
        "enable": "Включить",
        "exclude": "Исключить",
        "clear": "Очистить",
        "name": "Название",
        "new": "Новая",
        "in_work": "В работе",
        "on_pause": "На паузе",
        "on_check": "На проверке",
        "on_rework": "На переделке",
        "completed": "Завершена",
        "to_work": "Начать выполнение",
        "to_pause": "Приостановить",
        "to_check": "На проверку",
        "to_rework": "Переделать",
        "to_completed": "Завершить",
        "to_new": "Новая",
        "dead_line": "Крайний срок",
        "priority": "Приоритет",
        "tasks.TaskModel": "задачу",
        "enter_your_message": "Введите сообщение",
        "reply_to_message": "Ответить на сообщение",
        "systemic": "Системное",
        "uncover": "Раскрыть",
        "collapse": "Свернуть",
        "comments": "Комментарии",
        "answers": "Ответы",
        "hide_replies": "Скрыть ответы",
        "load_more_answers": "Загрузить еще ответы",
        "answer_for": "ответ для",
        "load_more": "Загрузить еще",
        "enter_your_comment": "Введите комментарий",
        "comment2": "Комментарий",
        "comment_added": "Комментарий добавлен",
        "comment_delete": "Комментарий удален",
        "to_answer": "Ответить",
        "share_to_chat": "Поделиться",
        "edit_task": "Редактировать задачу",
        "add_task": "Добавить задачу",
        "select_date": "Выбрать дату",
        "select_performer": "Выбрать исполнителя",
        "auth": "Авторизация",
        "reg_text": "Еще нет учетной записи?",
        "reg": "Зарегистрируйтесь",
        "your_password": "Ваш пароль",
        "your_email": "Ваш E-mail",
        "sing_in": "Войти",
        "forgot_your_password": "Забыли пароль?",
        "field_required": "Обязательно для заполнения",
        "required_sym": "Минимум {sym} символов",
        "fill_required_fields": "Заполните обязательные поля",
        "authorisation_error": "Ошибка авторизации",
        "crm.GoodsOrderModel": "Заказ",
        "tasks.TaskSprintModel": "Спринт",
        "files": "Файлы",
        "remove": "Удалить",
        "pick_files": "Прикрепленные файлы",
        "file": "Файл",
        "image_show": "Просмотр изображения",
        "meetings.PlannedMeetingModel": "Собрание",
        "processes.FinancialApplicationModel": "Бизнес процесс",
        "change_avatar": "Изенить аватар",
        "delete_avatar": "Удалить аватар",
        "success_del_avatar": "Аватар удален",
        "success_avatar": "Аватар изменен",
        "max_file_size": "Максимальный размер файла {size}МБ",
        "file_size": "Размер файла",
        "megabytes": "Мб",
        "max_file_h_w": "Минимальная высота и ширина изображения {size}px",
        "update": "Обновить",
        "file_modal_title": "Отправить собщение с файлом",
        "file_max_count": "В сообщении можно отправить только {count} файлов",
        "file_and_image": "Файл/Изображение"
    }
}
